// src/lib/graphqlClient.ts
const GRAPHQL_ENDPOINT = 'https://api.healthcarelocator.com/api/graphql/v2/query'

export const graphQlRequest = async <T>(query: string, variables?: Record<string, unknown>): Promise<T> => {
  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '303d2050ed624496',
    },
    body: JSON.stringify({ query, variables }),
  })

  const responseBody = await response.json()
  if (responseBody.errors) {
    throw new Error(JSON.stringify(responseBody.errors))
  }

  return responseBody.data
}
