import React, { useEffect, useState } from 'react'
import { Card } from '../Card'
import { CardWrapper } from '../Card.styled'
import { Select } from '../Select'
import { OPENING_HOURS_OPTIONS, SERVICE_OPTION_ALL, SERVICE_OPTIONS } from '../../constants/constants'
import { DownOutlined } from '@ant-design/icons'
import { Button } from '../Button.styled'
import { LocationSearchInput } from '../LocationSearchInput'
import { ReactComponent as IconSearch } from '../../../../assets/icons/search.svg'
import { Location, SelectProp } from '../../types'

interface FilterListProps {
  specializations: Array<{ name: string }>
  isLoading?: boolean
  disabled?: boolean
  error?: Error | null
  onSubmit: () => void
  onLocationChange: (a: Location) => void
  onSpecializationChange: (value: SelectProp[]) => void
  onServicesChange: (value: SelectProp[]) => void
  onOpeningChange: (value: SelectProp[]) => void
}

export const FilterList = ({
  onSubmit,
  onLocationChange,
  onSpecializationChange,
  onServicesChange,
  onOpeningChange,
  specializations,
  isLoading,
  disabled,
  error,
}: FilterListProps) => {
  const [specialties, setSpecialties] = useState<SelectProp[]>([])
  const [services, setServices] = useState<SelectProp[]>([SERVICE_OPTION_ALL])
  const [location, setLocation] = useState<Location>()

  const onServicesSelectChange = (isSelect: boolean, value: string, item: SelectProp) => {
    if (isSelect) {
      setServices((prev: SelectProp[]) => [...prev.filter((p) => p.name !== 'all'), item])
    } else {
      setServices((prev: SelectProp[]) => prev.filter((p) => p.name !== item.name))
    }
    if (item.name === 'all' && isSelect) {
      setServices([SERVICE_OPTION_ALL])
    }
  }

  // empty specialty array if Doctors isn't selected
  useEffect(() => {
    if (!services.find((s) => s.name === 'all' || s.name === 'doctor')) {
      setSpecialties([])
    }
    onServicesChange(services)
  }, [onServicesChange, services])

  if (error) return <div>Error: {error.message}</div>

  return (
    <CardWrapper>
      <Card grid="1fr 2fr 1fr 2fr 1fr">
        <Card.Item>
          <div style={{ width: '100%' }}>
            <h4>Service</h4>

            <Select
              options={[...SERVICE_OPTIONS]}
              value={services}
              mode="multiple"
              placeholder="Select Item..."
              onSelect={(value: string, item: SelectProp) => onServicesSelectChange(true, value, item)}
              onDeselect={(value: string, item: SelectProp) => onServicesSelectChange(false, value, item)}
            />
          </div>
        </Card.Item>
        <Card.Item disable={!services.find((s) => s.name === 'all' || s.name === 'doctor')}>
          <div style={{ width: '100%' }}>
            <h4>Specialization</h4>
            <Select
              bordered={false}
              options={(specializations || []).map(({ name }: SelectProp) => ({
                name,
                value: name,
              }))}
              value={specialties}
              placeholder="Select Item..."
              maxTagCount="responsive"
              mode="multiple"
              style={{ width: '100%' }}
              onSelect={(_: string, option: SelectProp) => {
                const currentSpecialties: SelectProp[] = [...specialties, option]
                onSpecializationChange(currentSpecialties)
                setSpecialties(currentSpecialties)
              }}
              onDeselect={(spec: string) => {
                const currentSpecialties = specialties.filter((specialty) => specialty.name !== spec)

                onSpecializationChange(currentSpecialties)
                setSpecialties(currentSpecialties)
              }}
            />
          </div>
        </Card.Item>
        <Card.Item>
          <div style={{ width: '100%' }}>
            <h4>Opening hours</h4>
            <Select
              allowClear={false}
              suffixIcon={<DownOutlined />}
              bordered={false}
              options={[...OPENING_HOURS_OPTIONS]}
              onSelect={(value: string, option: SelectProp) => {
                onOpeningChange([option])
              }}
              defaultValue={OPENING_HOURS_OPTIONS[0].value}
              placeholder="Select Item..."
              maxTagCount="responsive"
              style={{ width: '100%' }}
            />
          </div>
        </Card.Item>
        <Card.Item>
          <div style={{ width: '100%' }}>
            <h4>Location</h4>
            <LocationSearchInput
              onSelect={(_, coordinates, country) => {
                setLocation({ coordinates, country })
                onLocationChange({ coordinates, country } as Location)
              }}
            />
          </div>
        </Card.Item>
        <Card.Item justify="flex-end">
          <Button
            disabled={disabled || !location}
            type="primary"
            icon={<IconSearch />}
            onClick={onSubmit}
            loading={isLoading}
          >
            Search
          </Button>
        </Card.Item>
      </Card>
    </CardWrapper>
  )
}
