import { useEffect, useRef, useState } from 'react'
import { GoogleService, PlaceDetailsResult, PlaceResult } from '../types/googlePlacesTypes'

declare global {
  interface Window {
    google: any
  }
}
const GOOGLE_API_KEY = window.xundEnvironment?.GOOGLE_API_KEY

export function useGooglePlacesApi() {
  const googleScriptLoaded = useRef(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const placesScriptApiId = 'google-places-api'
  useEffect(() => {
    const loadGoogleMapsScript = (callback: () => void) => {
      if (window.google || document.getElementById(placesScriptApiId)) {
        callback()
        return
      }
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`
      script.async = true
      script.id = placesScriptApiId
      script.onload = callback
      document.body.appendChild(script)
    }

    loadGoogleMapsScript(() => {
      googleScriptLoaded.current = true
      setIsLoaded(true)
    })
  }, [])

  const getSuggestions = (inputValue: string) => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject('Google Maps API not loaded')
        return
      }
      const autocompleteService = new window.google.maps.places.AutocompleteService()
      autocompleteService.getPlacePredictions({ input: inputValue }, (predictions: unknown[], status: unknown) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          resolve(predictions)
        } else {
          reject(status)
        }
      })
    })
  }

  const fetchPlaceDetails = (placeId: string) => {
    return new Promise((resolve, reject) => {
      if (!window.google || !window.google.maps) {
        reject('Google Maps API not loaded')
        return
      }
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
      placesService.getDetails(
        { placeId },
        (
          place: { geometry: { location: { lat: () => void; lng: () => void } }; address_components: never[] },
          status: unknown,
        ) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
            const coordinates = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }
            const addressComponents = place.address_components || []
            const countryComponent = (addressComponents as Array<{ types: string[]; short_name: string }>).find(
              (component) => component.types.includes('country'),
            )
            const country = countryComponent ? countryComponent.short_name : 'Unknown'

            resolve({ coordinates, country })
          } else {
            reject(status)
          }
        },
      )
    })
  }

  const nearBySearch = (request: {
    location: { lat: number; lng: number }
    radius: number
    type: 'doctor' | 'hospital' | 'pharmacy'
    pagetoken?: string
    keyword?: string
    pagination?: { next_page_token?: string }
  }) => {
    if (!window.google || !window.google.maps) {
      throw new Error('Google Maps API not loaded')
    }
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))

    type GooglePlacesResuls = { res: PlaceResult[]; status: unknown; pagination: { next_page_token: string } }

    return new Promise<GooglePlacesResuls>((resolve) => {
      placesService.nearbySearch(
        request,
        (res: PlaceResult[], status: unknown, pagination: { next_page_token: string }) => {
          resolve({ res, status, pagination })
        },
      )
    }).then(async ({ res, status, pagination }) => {
      const places = []
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        for (const place of res) {
          const newPlace = (await getPlaceDetails(placesService, place.place_id, place)) as PlaceResult
          places.push({ ...place, ...newPlace })
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('PlacesService failed:', status)
      }

      const detailedResults = {
        places,
        pagination,
      }

      return detailedResults
    })
  }

  const getPlaceDetails = async (service: GoogleService, placeId: string, place: PlaceResult) => {
    return new Promise((resolve) => {
      const request = {
        placeId,
        fields: ['name', 'vicinity', 'formatted_phone_number', 'opening_hours'],
        language: 'de',
      }

      service.getDetails(request, (details: PlaceDetailsResult | null, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve({ ...details, openingHours: details?.opening_hours })
        }
      })
    })
  }

  const getAddressFromCoordinates = async (coord: {
    latitude: number
    longitude: number
  }): Promise<{ address: string; country: string } | null> => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder()
      const latlng = new window.google.maps.LatLng(coord.latitude, coord.longitude)

      geocoder.geocode(
        { location: latlng },
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            const country = results[0].address_components.find((component) =>
              component.types.includes('country'),
            )?.short_name
            resolve({
              address: results[0].formatted_address,
              country: country || 'Unknown',
            })
          } else {
            resolve(null)
          }
        },
      )
    })
  }

  return { isLoaded, getSuggestions, fetchPlaceDetails, nearBySearch, getAddressFromCoordinates }
}
