import { useState, useEffect, useRef } from 'react'

import { graphQlRequest } from '../graphQl/graphQlClient'

import { GET_DOCTORS } from '../graphQl/queries/getDoctors'

declare global {
  interface Window {
    HclAPI: any
  }
}

export const useDoctorSearch = () => {
  const [isLoading, setIsLoading] = useState(false)

  const hcl = useRef<{
    activities: (props: any) => Promise<any>
    workplaceByID: (props: { id: string; locale: string }) => Promise<any>
  } | null>(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.onload = async function () {
      hcl.current = new window.HclAPI({
        apiKey: window.xundEnvironment?.IQVIA_API_KEY,
      })
      setIsLoading(false)
    }
    script.src = 'https://static.healthcarelocator.com/v1/hcl-sdk-api/hcl-sdk-api.js'

    document.head.appendChild(script) //or something of the likes
    setIsLoading(true)
  }, [])

  const search = async (props: any) => {
    if (!hcl.current) {
      return
    }

    setIsLoading(true)

    const searchResults: { activities: { edges: any[] } } = await graphQlRequest(GET_DOCTORS(props))

    setIsLoading(false)

    return searchResults
  }

  return { search, isLoading }
}
