import { useState } from 'react'
import { useGooglePlacesApi } from './useGooglePlacesApi'
import { upperCaseFirstLetter } from '../utils/upperCaseFirstLetter'
import { PlaceResult } from '../types/googlePlacesTypes'
import { ResultListProp, SearchProps, Services } from '../types'
import { useDoctorSearch } from './useDoctorSearch'
import { getDist } from '../utils/getDistance'
// import { useDoctorSearch } from './useDoctorSearch'
// import { GetMappedSpecializationKey } from '../utils/getMappedSpecializationKey'

const isIqviaEnabled = false

export const useProviderSearch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<ResultListProp[]>()

  const { nearBySearch, isLoaded: googleIsLoaded } = useGooglePlacesApi()

  const { search: doctorSearch } = useDoctorSearch()

  const mapIqviaResponse = (response: { activities: { edges: any[] } }) => {
    return response?.activities?.edges.map((edge) => {
      const { individual, workplace } = edge.node

      return {
        id: edge.node.id,
        name: `${individual.firstName} ${individual.lastName}`,
        specialization: individual.professionalType.label,
        openingHours: workplace.address.openHours,
        address: `${workplace.address.longLabel}, ${workplace.address.postalCode} ${workplace.address.county.label}`,
        phone: workplace.intlPhone,
        location: { lat: workplace.address.location.lat, lng: workplace.address.location.lon },
      }
    })
  }
  const mapGoogleResponse = (response: { places: PlaceResult[] }, serviceType: string, service: Services) => {
    return response.places.map((details: PlaceResult, index: number) => {
      return {
        id: details.id || `${details.place_id}-${index}`,
        name: details.name,
        service,
        specialization: serviceType,
        address: details.vicinity,
        phone: details.formatted_phone_number || 'N/A',
        openNow: details.opening_hours?.isOpen() ? 'Open Now' : 'Closed',
        openingHours: details.openingHours?.weekday_text || ['No hours available'],
        periods: details.openingHours?.periods || [],
        location: {
          lat: details.geometry.location.lat(),
          lng: details.geometry.location.lng(),
        },
        place_id: details.place_id,
      }
    })
  }

  type Place = {
    name: string
    vicinity: string
    geometry: {
      location: {
        lat: Function
        lng: Function
      }
    }
    openingHours: any
  }

  const findMatchingDoctorDetails = (
    places: Place[],
    name?: string,
    address?: string,
    location?: { lat: number; lng: number },
  ) => {
    let bestMatch: Place | null = null
    let highestScore = 0

    for (const place of places) {
      let score = 0

      if (address && place.vicinity.toLowerCase() === address.toLowerCase()) {
        score += 2
      }

      if (location) {
        const loc = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        const distance = getDist(location, loc)

        if (distance < 0.01) {
          score += 1
        }
      }

      if (score > highestScore && name && place.name.toLowerCase().includes(name.toLowerCase())) {
        highestScore = score
        bestMatch = place
      }
    }

    return bestMatch
  }

  const search = async (props: SearchProps) => {
    const requests = []
    const { location } = props

    setIsLoading(true)

    const { specializations } = props
    if (isIqviaEnabled && props.specializations) {
      const doctorResults = await doctorSearch({
        specialties: ['SP.WAT.39'],
        coordinates: location.coordinates,
      })
      // console.log({ doctorResults })

      if (doctorResults) {
        for (const index in doctorResults.activities.edges) {
          const edge = doctorResults.activities.edges[index]

          const resultEdge = { ...edge } // Create a shallow copy to avoid mutating the original object
          const { workplace, individual } = edge.node
          const searchParams: {
            location: { lat: number; lng: number }
            radius: number
            type: 'doctor' | 'hospital' | 'pharmacy'
          } = {
            location: {
              lat: workplace.address.location.lat,
              lng: workplace.address.location.lon,
            },
            radius: 500,
            type: 'doctor',
          }
          const details1 = await nearBySearch(searchParams)
          const details2 = await nearBySearch({
            ...searchParams,
            pagetoken: details1.pagination?.next_page_token,
          })
          const details3 = await nearBySearch({
            ...searchParams,
            pagetoken: details2.pagination?.next_page_token,
          })

          const matchingDetails = findMatchingDoctorDetails(
            [details1.places, details2.places, details3.places].flat() as Place[],
            `${individual.lastName}`,
            `${workplace.address.longLabel}, ${workplace.address.postalCode} ${workplace.address.county.label}`,
            location.coordinates,
          )

          console.log(
            `name: ${individual.firstName} ${individual.lastName} (${edge.node.workplace.officialName}), `,
            `address: ${workplace.address.longLabel}, ${workplace.address.postalCode} ${workplace.address.county.label}`,
            `web: ${individual.webAddress}`,
            `web workplace: ${workplace.webAddress}`,
            location.coordinates,
            { lat: edge.node.workplace.address.location.lat, lng: edge.node.workplace.address.location.lon },
            { details: [details1.places, details2.places, details3.places].flat().map((a) => a.name), matchingDetails },
          )

          if (matchingDetails?.openingHours) {
            resultEdge.node.workplace.openHours = matchingDetails.openingHours
          }
          doctorResults.activities.edges[index] = resultEdge
        }
        requests.push(mapIqviaResponse(doctorResults))
      }
    }

    if (location?.coordinates) {
      for (const serviceType of props.serviceTypes) {
        if (serviceType.name === 'doctor' && specializations && specializations.length > 0 && !isIqviaEnabled) {
          for (const specialization of specializations) {
            try {
              const result = await nearBySearch({
                location: location.coordinates,
                radius: 5000,
                type: 'doctor',
                keyword: specialization,
              })

              requests.push(
                Promise.resolve(
                  mapGoogleResponse(
                    result as { places: PlaceResult[] },
                    upperCaseFirstLetter(specialization),
                    serviceType.name,
                  ),
                ),
              )
              // avoid exceeding quota:
              await new Promise((resolve) => setTimeout(resolve, 100))
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(`Error searching for ${specialization}:`, error)
            }
          }
        } else {
          try {
            const result = await nearBySearch({
              location: location.coordinates,
              radius: 5000,
              type: serviceType.name,
            })
            requests.push(
              Promise.resolve(
                mapGoogleResponse(
                  result as { places: PlaceResult[] },
                  upperCaseFirstLetter(serviceType.name),
                  serviceType.name,
                ),
              ),
            )
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error searching for ${serviceType.name}:`, error)
          }
        }
      }
    }

    const responses = await Promise.all(requests)

    setIsLoading(false)

    setResults(responses.flat() as ResultListProp[])
  }

  return { search, isLoading, results, googleIsLoaded }
}
