import React, { useState, useEffect } from 'react'
import { Input, AutoComplete, Button } from 'antd'
import { ReactComponent as IconLocation } from '../../../assets/icons/location-icon.svg'
import { useGooglePlacesApi } from '../hooks/useGooglePlacesApi'

interface LocationSearchInputProps {
  onSelect?: (location: string, coordinates: { lat: number; lng: number }, country: string) => void
}

interface PlacePrediction {
  description: string
  place_id: string
}

const LocationSearchInput: React.FC<LocationSearchInputProps> = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState<Array<{ value: string; id: string }>>([])
  const [address, setAddress] = useState<string>('')

  const placesApi = useGooglePlacesApi()
  const { getAddressFromCoordinates } = useGooglePlacesApi()

  useEffect(() => {
    if (!inputValue || !window.google) return

    placesApi.getSuggestions(inputValue).then((predictions: unknown) => {
      setSuggestions(
        (predictions as PlacePrediction[]).map((place) => ({
          value: place.description,
          id: place.place_id,
        })),
      )
    })
  }, [inputValue, placesApi])

  const onLocateButtonClick = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const detectedAddress: { address: string; country: string } | null = await getAddressFromCoordinates(
          position.coords,
        )
        console.log({ detectedAddress })

        if (detectedAddress) {
          setAddress(detectedAddress.address)
          if (onSelect)
            onSelect(
              detectedAddress.address,
              { lat: position.coords.latitude, lng: position.coords.longitude },
              detectedAddress.country,
            )
        }
      })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  const fetchPlaceDetails = (placeId: string, description: string) => {
    if (!window.google) return
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
    placesService.getDetails(
      { placeId },
      (
        place: { geometry: { location: { lat: Function; lng: Function } }; address_components: [unknown] },
        status: unknown,
      ) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
          const coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }

          const addressComponents = place.address_components || []
          const countryComponent = (addressComponents as Array<{ types: string[]; short_name: string }>).find(
            (component) => component.types.includes('country'),
          )
          const country = countryComponent ? countryComponent.short_name : 'HU'

          if (onSelect) onSelect(description, coordinates, country)
        }
      },
    )
  }

  return (
    <AutoComplete
      options={suggestions}
      onSelect={(value, option) => {
        setInputValue(value)
        fetchPlaceDetails(option.id, value)
      }}
      value={address}
      onChange={(value) => setAddress(value)}
      onSearch={setInputValue}
      style={{ width: '100%' }}
    >
      <Input
        placeholder="Enter an address"
        suffix={
          <Button
            onClick={onLocateButtonClick}
            shape="circle"
            type="text"
            size="small"
            icon={<IconLocation style={{ marginTop: '5px' }} />}
          />
        }
      />
    </AutoComplete>
  )
}

export { LocationSearchInput }
