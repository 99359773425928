import React, { FC, useCallback, useMemo, useState } from 'react'
import { MedicalEngineIllnessCheckReport, MedicalEngineSymptomCheckReport } from 'medical-engine-api'
import { LeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import {
  ChatbotQuestionAnswer,
  OverviewResponse,
  Question,
  ResponseType,
  ResponseTypeWithoutReport,
} from '../../models'
import { IconButton } from '../General/IconButton/IconButton'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { mobileMaxWidth, useI18n } from '../../hooks'
import { useQuestionContext } from '../../context/QuestionContext'
import { RestartModal } from '../Modals/RestartModal/RestartModal'
import { CHECK_TYPE_SYMPTOM_CHECK, useApiGatewayContext, useAppStateContext } from '../../context'
import styles from './Footer.module.less'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ACTION_REPORT, CATEGORY_ILLNESS_CHECK, CATEGORY_SYMPTOM_CHECK } from '../../models/Matomo'
import { BaseModal } from '../Modals/BaseModal/BaseModal'
import { OptionItem } from '../../models/api/response/OptionItem'
import { useDownloadReportAsPDF } from '../../hooks/useDownloadReportAsPDF'

import { CSS_VARS } from '../../resources/cssVariableConfig'

const secondToLastQuestionIndex = 2

/**
 * @param props The props object
 * @param props.chatbotQuestionData The current question data
 * @param props.showReportActions The boolean that decides whether to show report actions
 * @param props.isCheckFinished The boolean that decides whether the check is finished
 * @param props.onBackClick The method handling back button clicks
 * @param props.onSendAnswer The method for sending answers
 * @param props.onRestartCheck A method handling the restart of the current check
 * @returns The footer component
 */
export const FooterReportRedesign: FC<{
  chatbotQuestionData: ResponseType[]
  showReportActions: boolean
  isCheckFinished: boolean
  continueCheck: boolean
  onBackClick: () => void
  onSendAnswer: (answer: ChatbotQuestionAnswer, isInfoQuestion?: boolean) => void
  onRestartCheck: () => void
}> = ({
  chatbotQuestionData,
  showReportActions,
  isCheckFinished,
  continueCheck,
  onBackClick,
  onSendAnswer,
  onRestartCheck,
}) => {
  const { i18n } = useI18n()
  const {
    hideSkipButton,
    currentResponse,
    setIsReporting,
    nextButtonI18nKey,
    nextButtonLogic,
    isLoading,
    isNextButtonDisabled,
    isReporting,
    checkType,
    directCheck,
  } = useQuestionContext()
  const { trackEvent } = useMatomo()
  const [isRestartModalVisible, setIsRestartModalVisible] = useState(false)
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)
  // const [isDownloading, setDownloading] = useState(false)
  const { apiGateway } = useApiGatewayContext()
  const { isContentLibraryOpen, isCheckOnlyMode, isRestartDisabled } = useAppStateContext()

  const { width: windowWidth } = useWindowSize()

  const isMedicalResearchMode = window.xundEnvironment.APP_MODE === 'MEDICAL_RESEARCH'
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const isPreviousQuestionAReport = useMemo(
    () =>
      !!(
        chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as
          | MedicalEngineSymptomCheckReport
          | MedicalEngineIllnessCheckReport
      )?.illnessCategories,
    [chatbotQuestionData],
  )

  const isPreviousQuestionOverview = useMemo(
    () =>
      !!(chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as OverviewResponse)
        ?.addedSymptoms,
    [chatbotQuestionData],
  )

  const isContinueCheckException = useMemo(() => {
    const hasMoreDisclaimers =
      chatbotQuestionData.filter((question) =>
        ['ILLNESS_CHECK_LEGAL_DISCLAIMER', 'SYMPTOM_CHECK_LEGAL_DISCLAIMER'].includes(
          (question as Question)?.checkStateInfo?.detailedState,
        ),
      ).length > 1

    const isOverview = !!(chatbotQuestionData[0] as OverviewResponse)?.addedSymptoms

    // TODO rename
    const isAfter = ['AFTER_OVERVIEW_SYMPTOM_SURVEY', 'SYMPTOM_CHECK_ILLNESS_SURVEY_INFO'].includes(
      (chatbotQuestionData[chatbotQuestionData.length - 1] as Question)?.checkStateInfo?.detailedState,
    )

    const isDisclaimer = ['ILLNESS_CHECK_LEGAL_DISCLAIMER', 'SYMPTOM_CHECK_LEGAL_DISCLAIMER'].includes(
      (chatbotQuestionData[chatbotQuestionData.length - 1] as Question)?.checkStateInfo?.detailedState,
    )

    return continueCheck && (isOverview || isAfter || (isDisclaimer && hasMoreDisclaimers))
  }, [chatbotQuestionData, continueCheck])

  const isShowingBackButtonNecessary = useMemo(
    () =>
      !showReportActions &&
      !isCheckFinished &&
      !isPreviousQuestionAReport &&
      (!isPreviousQuestionOverview || (currentResponse as ResponseTypeWithoutReport)?.detailedType === 'EMERGENCY') &&
      !isReporting &&
      !isContinueCheckException,
    [
      showReportActions,
      isCheckFinished,
      isPreviousQuestionAReport,
      isPreviousQuestionOverview,
      currentResponse,
      isReporting,
      isContinueCheckException,
    ],
  )

  const hideInitialBackButton = useMemo(
    () =>
      (isCheckOnlyMode || directCheck) &&
      (currentResponse as ResponseTypeWithoutReport)?.detailedType === 'TERMS_AND_CONDITIONS',
    [currentResponse, directCheck, isCheckOnlyMode],
  )

  const isBackButtonVisible = useMemo(
    () =>
      (!hideInitialBackButton &&
        (currentResponse as ResponseTypeWithoutReport)?.detailedType !== 'START_SCREEN' &&
        isShowingBackButtonNecessary) ||
      isContentLibraryOpen,
    [currentResponse, hideInitialBackButton, isContentLibraryOpen, isShowingBackButtonNecessary],
  )

  const { download, isDownloading } = useDownloadReportAsPDF()

  const sendReportConfirmation = useCallback(async () => {
    setConfirmModalVisible(false)
    await apiGateway.post('v1/chatbot/report/confirm')

    trackEvent({
      category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
      action: ACTION_REPORT,
      name: 'End Check button clicked',
    })

    const { data } = await apiGateway.get<Question>('/v1/chatbot/question')

    // answer last question automatically since the user confirmed the check ending
    const endCheckAnswerId = 'a2dc1dd9-f8be-4d0e-ac50-f4d06b7cf9ed'
    if (data.options?.values?.find((a: OptionItem) => a.id === endCheckAnswerId)) {
      const answer: ChatbotQuestionAnswer = {
        questionId: (data as Question)?.id,
        answer: { id: endCheckAnswerId },
      }
      await onSendAnswer(answer)
      setIsReporting(false)
    }
  }, [apiGateway, checkType, onSendAnswer, setIsReporting, trackEvent])

  const confirmReport = useCallback(async () => {
    setConfirmModalVisible(true)
  }, [])

  const handleSkipQuestion = useCallback(() => {
    trackEvent({
      category: checkType === CHECK_TYPE_SYMPTOM_CHECK ? CATEGORY_SYMPTOM_CHECK : CATEGORY_ILLNESS_CHECK,
      action: 'Question skipped',
      name: `${(currentResponse as Question).text} (${(currentResponse as Question).id})`,
    })
    onSendAnswer({ questionId: (currentResponse as Question).id })
  }, [checkType, currentResponse, onSendAnswer, trackEvent])

  const showMiddleButton = ((currentResponse as Question)?.isSkippable ?? false) && !hideSkipButton

  const breakPoints = {
    col1: { xs: 24, sm: 8, md: 8 },
    col2: showMiddleButton ? { xs: 24, sm: 8, md: 8 } : {},
    col3: showMiddleButton ? { xs: 24, sm: 4, md: 4 } : { xs: 24, sm: 8, md: 8 },
    col4: showMiddleButton ? { xs: 24, sm: 4, md: 4 } : { xs: 24, sm: 8, md: 8 },
  }

  const commonCol1Content = isBackButtonVisible && (
    <>
      {!isMobile && (
        <div style={{ display: 'flex', marginLeft: -15 }}>
          <IconButton
            color={CSS_VARS.primaryColor}
            testId="backButton"
            icon={<LeftOutlined />}
            onClick={onBackClick}
            disabled={isLoading}
          />
        </div>
      )}
      {isMobile && (
        <StyledButton
          type="skip"
          title={i18n('xund.general.back')}
          disabled={isLoading}
          onClick={onBackClick}
          style={{ width: '100%' }}
        />
      )}
    </>
  )

  if (isContentLibraryOpen) {
    return (
      <div className={styles.footer} id="footer">
        <Row>
          <Col {...breakPoints.col1}>{commonCol1Content}</Col>
        </Row>
        <div />
        {isRestartModalVisible && !isRestartDisabled && (
          <RestartModal
            onConfirm={() => {
              onRestartCheck()

              setIsRestartModalVisible(false)
            }}
            onCancel={() => setIsRestartModalVisible(false)}
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.footer} id="footer" data-testid="footer">
      <Row>
        <Col {...breakPoints.col1} order={isMobile ? 4 : 0}>
          {isReporting && !isRestartDisabled && (
            <StyledButton
              type="skip"
              title={i18n('xund.general.restartCheck')}
              onClick={() => setIsRestartModalVisible(true)}
              disabled={isLoading}
              classNames={[styles.reportButton, styles.restartButton]}
            />
          )}
          {commonCol1Content}
        </Col>
        {showMiddleButton && (
          <Col {...breakPoints.col2} order={isMobile ? 3 : 0}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                testId="skipButton"
                type="skip"
                title={
                  (currentResponse as Question).detailedType === 'SYMPTOM_SUGGESTION'
                    ? i18n('xund.general.noneOfThese')
                    : i18n('xund.general.skip')
                }
                disabled={isLoading}
                onClick={handleSkipQuestion}
                style={{ marginBottom: isMobile ? 10 : 'unset' }}
                classNames={[
                  (currentResponse as Question).detailedType === 'SYMPTOM_SUGGESTION'
                    ? styles.noneOfTheseButton
                    : styles.skipButton,
                ]}
              />
            </div>
          </Col>
        )}
        <Col {...breakPoints.col3} className={styles.endCheckCol} order={0}>
          {isReporting && (
            <StyledButton
              type="confirm"
              title={i18n('xund.general.downloadReport')}
              disabled={isLoading}
              isLoading={isDownloading}
              onClick={download}
              classNames={[styles.reportButton, styles.pdfButton]}
            />
          )}
        </Col>
        <Col {...breakPoints.col4} style={{ textAlign: 'end' }} order={isMobile ? 1 : 0}>
          {(currentResponse as Question)?.detailedType === 'EMERGENCY'
            ? (currentResponse as Question)?.options?.values?.map((option, index) => (
                <div
                  key={`emergency-option-${option.id}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: ((currentResponse as Question)?.options?.count ?? 0) > 1 ? 12 : 0,
                  }}
                >
                  <StyledButton
                    testId={`emergencyButton-${index}`}
                    type="filled"
                    title={option.text}
                    disabled={isLoading}
                    onClick={() =>
                      onSendAnswer({
                        questionId: (currentResponse as Question)?.id,
                        answer: { id: option.id },
                      })
                    }
                    classNames={[styles.emergencyButton]}
                  />
                </div>
              ))
            : !isMedicalResearchMode &&
              ['ILLNESS_CHECK_LEGAL_DISCLAIMER', 'SYMPTOM_CHECK_LEGAL_DISCLAIMER'].includes(
                (currentResponse as Question)?.checkStateInfo?.detailedState,
              ) && (
                <StyledButton
                  type="confirm"
                  title={`${(currentResponse as Question)?.options?.values[0].text}`}
                  disabled={isLoading}
                  onClick={() => {
                    onSendAnswer({
                      questionId: (currentResponse as Question)?.id,
                      answer: { id: (currentResponse as Question)?.options?.values[0].id },
                    })
                  }}
                  classNames={[styles.reportButton]}
                />
              )}
          {nextButtonLogic && (
            <StyledButton
              type="confirm"
              //TODO refactor question button title/logic settings
              title={
                (currentResponse as Question)?.detailedType === 'PROGRESS'
                  ? i18n('xund.general.next')
                  : i18n(nextButtonI18nKey)
              }
              onClick={nextButtonLogic}
              disabled={isLoading || isNextButtonDisabled}
              classNames={[styles.nextButton]}
              style={{ marginBottom: isMobile ? 10 : 'unset' }}
            />
          )}
          {isReporting && (
            //TODO create button variants
            <StyledButton
              type="skip"
              title={i18n('xund.general.endCheck')}
              onClick={confirmReport}
              disabled={isLoading}
              classNames={[styles.reportButton]}
            />
          )}
        </Col>
      </Row>
      <div />
      {isRestartModalVisible && !isRestartDisabled && (
        <RestartModal
          onConfirm={() => {
            onRestartCheck()

            setIsRestartModalVisible(false)
          }}
          onCancel={() => setIsRestartModalVisible(false)}
        />
      )}

      {isConfirmModalVisible && (
        <BaseModal
          onCancel={() => setConfirmModalVisible(false)}
          onOk={() => sendReportConfirmation()}
          okBtnTitle={i18n('xund.general.endCheck.modal.button.approve')}
          cancelBtnTitle={i18n('xund.general.endCheck.modal.button.deny')}
          title={i18n('xund.general.endCheck.modal.title')}
        >
          <Row>
            <Col span={24}>
              <p>{i18n('xund.general.endCheck.modal.description')}</p>
            </Col>
          </Row>
        </BaseModal>
      )}
    </div>
  )
}
