import styled from '@emotion/styled'
import { Button } from 'antd'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

type Types = 'confirm' | 'outlined' | 'skip' | 'filled' | 'default' | 'primary'

export const StyledButtonStyled = styled(Button)<{
  buttontype: Types
  ismobile: number
  disabled: boolean | undefined
  isloading: number
}>`
  border-width: 0.5px;
  border-radius: 1.2em;
  height: auto;
  padding: 0.3em 1.2em 0.2em 1.2em !important;

  ${(props) => (props.ismobile ? `height: auto; word-break: break-word; white-space: normal;` : ``)};
  ${(props) => (props.disabled && props.isloading ? `opacity: 0.4;` : ``)};

  ${(props) => {
    if (props.buttontype === 'confirm') {
      return `
        color: ${CSS_VARS.confirmButtonFontColor};
        background-color: ${CSS_VARS.successColor};
        border-color: ${CSS_VARS.successColor};
        font-weight: ${CSS_VARS.buttonWeight};
        font-size: ${CSS_VARS.fontSize};
        :hover {
          background-color: ${CSS_VARS.successColor}!important;
        }
      `
    } else if (props.buttontype === 'outlined') {
      return `
        color: ${CSS_VARS.outlinedButtonFontColor};
        background-color: ${CSS_VARS.outlinedButtonBackgroundColor};
        border-color: ${CSS_VARS.outlinedButtonBorderColor};
        font-weight: ${CSS_VARS.buttonWeight};
        font-size: ${CSS_VARS.fontSize};
      `
    } else if (props.buttontype === 'skip') {
      return `
        color: ${CSS_VARS.skipButtonFontColor};
        background-color: ${CSS_VARS.skipButtonBackgroundColor};
        border-color: ${CSS_VARS.skipButtonBorderColor};
        font-weight: ${CSS_VARS.buttonWeight};
        font-size: ${CSS_VARS.fontSize};
      `
    } else if (props.buttontype === 'filled') {
      return `
        color: ${CSS_VARS.filledButtonFontColor};
        background-color: ${CSS_VARS.primaryColor};
        border-color: ${CSS_VARS.primaryColor};
        font-weight: ${CSS_VARS.buttonWeight};
        font-size: ${CSS_VARS.fontSize};
      `
    } else {
      return `
        color: ${CSS_VARS.skipButtonFontColor};
        background-color: ${CSS_VARS.skipButtonBackgroundColor};
        border-color: ${CSS_VARS.skipButtonBorderColor};
        font-weight: ${CSS_VARS.buttonWeight};
        font-size: ${CSS_VARS.fontSize};
      `
    }
  }};
`

export const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
