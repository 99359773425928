export const GET_DOCTORS = (props: { specialties: string[]; coordinates: { lat: number; lng: number } }) => {
  return `query {
  activities(first: 6, offset: 0, country: "AT",
    ${props.specialties ? `specialties: ${JSON.stringify(props.specialties)}` : ``}
    location: { lat: ${props.coordinates.lat}, lon: ${props.coordinates.lng}, distanceMeter: 5000},
    ) {
    
    totalCount
    edges {
      node {
        
        individual {
          firstName
          lastName
          title {
            code
            label
          },
          webAddress
          specialties {
          code
            label
          }
          professionalType {
            code
            label
          }
        }
        workplace {
          name
          officialName
          address{
            longLabel, 
            postalCode, 
            country, 
            county { label } 
            location {
              lat
              lon
            }
          }
          webAddress
          localPhone
          intlPhone
          openHours {
            day
            openPeriods {
              open
              close
            }
          }
        }
      }
    }
  }
}
`
}
