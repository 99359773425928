import { Button } from './IconButton.styled'
import React, { CSSProperties, FC, ReactNode } from 'react'

/**
 * @param props The props object
 * @param props.icon The icon to use
 * @param props.disabled Whether the button is disabled
 * @param props.onClick The method handling clicks
 * @param props.testId Optional id used as selector in tests
 * @param props.style The styles to use
 * @returns The IconButton component
 */
export const IconButton: FC<{
  testId?: string
  icon: ReactNode
  disabled?: boolean
  onClick?: () => void
  style?: CSSProperties
  tabIndex?: number
  color?: string
}> = ({ testId, icon, disabled, tabIndex, onClick, style, color }) => {
  return (
    <Button
      data-testid={testId}
      type="link"
      onClick={onClick}
      disabled={disabled}
      size="large"
      icon={icon}
      style={style}
      tabIndex={tabIndex}
      color={color}
    />
  )
}
